
export enum OS {
    Windows = 'Windows',
    MacOS = 'MacOS',
    Linux = 'Linux',
    Android = 'Android',
    iOS = 'iOS',
    Unknown = 'Unknown'
}

export enum DeviceType {
    Mobile = 'mobile',
    Pad = 'pad',
    PC = 'pc'
}